import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import linkedin from '../images/social/linkedin.svg';
import twitter from '../images/social/twitter.svg';
import facebook from '../images/social/facebook.svg';
import youtube from '../images/social/youtube.svg';

const SubFooter = props => (
  <div className="sub-footer-strip">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="sub-footer">
            <ul>
              <li>
                <strong>Email: </strong>
{' '}
                <a href={`mailto:${props.data.site.siteMetadata.contact.email}`}>
                  {props.data.site.siteMetadata.contact.email}
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <a href="https://groundspeedhq.com">www.groundspeedhq.com</a>
              </li>
            </ul>
            <ul>
              <li>
                <a href="https://www.linkedin.com/company/jadr-consulting-llc" aria-label="LinkedIn" target="_blank">
                  <img className="logo" alt="LinkedIn" src={linkedin} />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/groundspeedhq" aria-label="Twitter" target="_blank">
                  <img className="logo" alt="Twitter" src={twitter} />
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/GSHQ.CO/" aria-label="Facebook" target="_blank">
                  <img className="logo" alt="Facebook" src={facebook} />
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UCEAVy-kXCnaYGaoJihy7-bg" aria-label="YouTube" target="_blank">
                  <img className="logo" alt="Youtube" src={youtube} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            contact {
              email
              phone
            }
          }
        }
      }
    `}
    render={data => <SubFooter data={data} />}
  />
);
